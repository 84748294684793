<template>
  <div>
    <div class="field">
    <label class="label" v-if="labelTr">{{labelTr}}</label>
    <sqr-button
      v-if="!uploading && !value"
      :icon="icon"
      @click.native="selectFile"
      :label-raw="placeholderTr"
      :color="color"
      class="is-fullwidth"
      is-fullwidth
    />
      <div class="help" v-if="dndSupport">{{$t('uploader_dnd_available')}}</div>
    </div>
    <div class="field" v-if="dragging" @drop="drop" @dragenter="dragEnter" @dragleave="dragLeave">
      <div class="box is-shadowless" :class="dndZoneClass" style="border: 1px dashed;">
        {{$t('uploader_dnd_drop_here')}}
      </div>
    </div>
    <input
      id="files"
      type="file"
      name="file"
      ref="uploadInput"
      :multiple="true"
      @change="uploadFiles($event)"
    />
    <div class="field" v-if="filesCount > 1">
      <label class="label">{{$t('uploader_files')}}</label>
      <sqr-progress :value="fileNum" :max="filesCount" color="primary" />
    </div>
    <div class="field" v-if="uploading">
      <label class="label">{{$t('uploader_upload')}}</label>
      <sqr-progress :value="bytesTransferred" :max="totalBytes" color="primary" />
    </div>
    <figure class="image" v-if="value">
      <div class="is-pulled-right">
        <div class="buttons">
          <sqr-button
            icon="trash"
            class="ma-0"
            @click="deleteImage()"
            color="white"
            :is-loading="deleting"
          />
        </div>
      </div>
      <img :src="value.downloadURL" width="100%" :alt="value.name" :title="value.name" />
    </figure>
    <div v-if="value"></div>
  </div>
</template>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type='file'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

<script>
import label from './mixins/label';
import color from './mixins/color';
import placeholder from './mixins/placeholder';

import SqrProgress from './SqrProgress';

export default {
  name: 'SqrFilesUploader',
  components: {SqrProgress},
  mixins: [label, color, placeholder],
  props: {
    value: { type: Object },
    prefix: { type: String, required: true},
    icon: { type: String },
    color: { type: String},
  },
  computed: {
    dndSupport () {
      var div = document.createElement('div');
      return ('FormData' in window) && ('FileReader' in window) &&
        ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
    },
    dndZoneClass () {
      return {
        'has-background-success': this.draggingOver
      }
    }
  },
  data() {
    return {
      dragging: false,
      draggingOver: false,

      uploading: false,
      deleting: false,
      bytesTransferred: null,
      totalBytes: 0,
      uploadTask: '',

      fileNum: 0,
      filesCount: 0
    };
  },
  mounted () {
    window.addEventListener('dragstart', this.winDragStart, true);
    window.addEventListener('dragenter', this.winDragEnter, true);
    window.addEventListener('dragover', this.winDragOver, true);
    window.addEventListener('dragend', this.winDragEnd, true);
    window.addEventListener('dragleave', this.winDragLeave, true);
    window.addEventListener('drop', this.winDrop, true);
  },
  beforeDestroy () {
    window.removeEventListener('dragstart', this.winDragStart, true);
    window.removeEventListener('dragenter', this.winDragEnter, true);
    window.removeEventListener('dragover', this.winDragOver, true);
    window.removeEventListener('dragend', this.winDragEnd, true);
    window.removeEventListener('dragleave', this.winDragLeave, true);
    window.removeEventListener('drop', this.winDrop, true);
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    async uploadFiles(e) {
      this.uploading = true;
      let fileList = e.target.files || e.dataTransfer.files;
      this.fileNum = 1;
      this.filesCount = fileList.length;
      const uploads = Array.from(Array(fileList.length).keys()).map(async x => {
        const result = await this.upload(fileList[x]);
        this.fileNum = this.fileNum + 1;
        return result;
      });
      const results = await Promise.all(uploads);
      this.$emit('files-uploaded', results);
      this.fileNum = 0;
      this.filesCount = 0;
      this.uploading = false;
    },
    async upload(file) {
      this.fileName = file.name;
      // this.uploading = true;
      const uploadTask = this.$fb()
        .storage()
        .ref(this.prefix + '/' + file.name)
        .put(file);
      uploadTask.on('state_changed', sp => {
        this.bytesTransferred = sp.bytesTransferred;
        this.totalBytes = sp.totalBytes;
      });
      this.uploadTask = uploadTask;
      const uploaded = await uploadTask;
      // const downloadURL = await uploaded.ref.getDownloadURL();
      const result = {
        contentType: uploaded.metadata.contentType,
        fullPath: uploaded.metadata.fullPath,
        name: uploaded.metadata.name,
        size: uploaded.metadata.size,
        // downloadURL
      };
      this.$emit('file-uploaded', result);
      // this.uploading = false;
      return result;
    },
    deleteImage() {
      if (this.value) {
        this.deleteImgOnFirebase();
      } else {
        this.uploading = false;
        this.$emit('change', null);
      }
    },
    async deleteImgOnFirebase() {
      try {
        this.deleting = true;
        await this.$fb()
          .storage()
          .ref('images/' + this.value.name)
          .delete();
        this.$emit('change', null);
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          this.$emit('change', null);
        } else {
          console.error('file delete error occured', error);
        }
      } finally {
        this.deleting = false;
      }
    },
    drop (event) {
      event.preventDefault();
      event.stopPropagation();
      // console.log('drop', event);
      const files = event.dataTransfer.files;
      this.uploadFiles(event);
    },
    dragEnter (event) {
      // console.log('dragEnter', event);
      this.draggingOver = true;
    },
    dragLeave (event) {
      // console.log('dragLeave', event);
      this.draggingOver = false;
    },
    winDragEnter (event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragEnter', event);
    },
    winDragStart (event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragStart', event);
    },
    winDragOver (event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragOver', event);
    },
    winDragLeave (event) {
      // event.stopPropagation();
      event.preventDefault();
      if (event.screenX === 0 && event.screenY === 0) {
        this.dragging = false;
      }
      // console.log('winDragLeave', event);
    },
    winDragExit (event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDragExit', event);
    },
    winDragEnd (event) {
      event.stopPropagation();
      event.preventDefault();
      // console.log('winDragEnd', event);
    },
    winDrop (event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDrop', event);
    }
  }
};
</script>
