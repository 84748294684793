var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('sqr-error',{attrs:{"error":_vm.loadError}}),_c('div',{staticClass:"flex h-screen"},[_c('div',{staticClass:"bg-white w-72"},[_c('aside',{staticClass:"sticky-upload p-2"},[_c('sqr-files-uploader',{attrs:{"icon":"upload","placeholder":"documents_upload","color":"primary","prefix":_vm.path},on:{"files-uploaded":function($event){return _vm.list()}}})],1),_c('aside',{staticClass:"menu"},[(_vm.parent)?_c('p',{staticClass:"menu-label"},[_vm._v(_vm._s(_vm.$t('documents_parents')))]):_vm._e(),(_vm.parent)?_c('ul',{staticClass:"menu-list"},[_c('li',[_c('router-link',{attrs:{"to":{
                name: 'documents',
                params: { folder: _vm.parent },
                query: _vm.$route.query
              }}},[_vm._v(_vm._s(_vm.$t('documents_back_to')))])],1)]):_vm._e(),(_vm.prefixes && _vm.prefixes.length > 0)?_c('p',{staticClass:"menu-label"},[_vm._v(" "+_vm._s(_vm.$t('documents_folders'))+" ")]):_vm._e(),_c('ul',{staticClass:"menu-list"},_vm._l((_vm.prefixes),function(prefix){return _c('li',{key:prefix.name},[_c('router-link',{attrs:{"to":{
                name: 'documents',
                params: { folder: prefix.path },
                query: _vm.$route.query
              }}},[_vm._v(" "+_vm._s(prefix.name)+" ")])],1)}),0),_c('p',{staticClass:"menu-label"},[_vm._v(_vm._s(_vm.$t('documents_documents')))]),_c('ul',{staticClass:"menu-list"},_vm._l((_vm.items),function(i){return _c('li',{key:i.name},[_c('a',{class:{ 'is-active': _vm.item && i.name === _vm.item.name },on:{"click":function($event){return _vm.view(i)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemsSelected),expression:"itemsSelected"}],attrs:{"type":"checkbox"},domProps:{"value":i,"checked":Array.isArray(_vm.itemsSelected)?_vm._i(_vm.itemsSelected,i)>-1:(_vm.itemsSelected)},on:{"change":function($event){var $$a=_vm.itemsSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=i,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.itemsSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.itemsSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.itemsSelected=$$c}}}}),_vm._v(" "+_vm._s(i.name)+" ")])])}),0)])]),_c('div',{staticClass:"flex-1"},[(_vm.itemsSelectedCount > 0)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-9-tablet is-6-desktop"},[_c('div',{staticClass:"buttons"},[(_vm.hasEmail)?_c('sqr-button',{attrs:{"icon":"envelope","label-raw":_vm.$tc('documents_selected_email', _vm.itemsSelectedCount, {
                    count: _vm.itemsSelectedCount
                  }),"size":"medium","color":"primary","is-fullwidth":"","is-loading":_vm.removing},on:{"click":function($event){return _vm.email()}}}):_vm._e(),_c('sqr-button',{attrs:{"icon":"trash","label-raw":_vm.$tc('documents_selected_remove', _vm.itemsSelectedCount, {
                    count: _vm.itemsSelectedCount
                  }),"size":"medium","color":"danger","is-fullwidth":"","is-loading":_vm.removing},on:{"click":function($event){return _vm.removeSelected()}}})],1)])])]):_vm._e(),(_vm.itemsSelectedCount === 0)?_c('div',{staticClass:"sticky-doc"},[_c('sqr-error',{attrs:{"error":_vm.itemLoadError}}),(_vm.item)?_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"button",attrs:{"href":_vm.itemURL,"target":"_blank"}},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":['fal', 'download']}})],1),_c('span',[_vm._v(_vm._s(_vm.$t('documents_download')))])]),(_vm.hasEmail)?_c('sqr-button',{attrs:{"icon":"envelope","label":"documents_email","is-loading":_vm.renaming},on:{"click":function($event){return _vm.email()}}}):_vm._e(),_c('sqr-button',{attrs:{"icon":"edit","label":"documents_rename","is-loading":_vm.renaming},on:{"click":function($event){return _vm.rename()}}}),_c('sqr-button',{attrs:{"icon":"trash","label":"documents_remove","is-loading":_vm.removing},on:{"click":function($event){return _vm.remove()}}})],1):_vm._e(),(_vm.itemURL)?_c('iframe',{staticStyle:{"height":"90vh"},attrs:{"src":_vm.itemURL,"width":"100%"}}):_vm._e()],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }