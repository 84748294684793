<template>
  <main>
    <sqr-error :error="loadError" />
    <div class="flex h-screen">
      <div class="bg-white w-72">
        <aside class="sticky-upload p-2">
          <sqr-files-uploader
            icon="upload"
            placeholder="documents_upload"
            color="primary"
            :prefix="path"
            @files-uploaded="list()"
          />
        </aside>
        <aside class="menu">
          <p class="menu-label" v-if="parent">{{ $t('documents_parents') }}</p>
          <ul class="menu-list" v-if="parent">
            <li>
              <router-link
                :to="{
                  name: 'documents',
                  params: { folder: parent },
                  query: $route.query
                }"
                >{{ $t('documents_back_to') }}</router-link
              >
            </li>
          </ul>
          <p class="menu-label" v-if="prefixes && prefixes.length > 0">
            {{ $t('documents_folders') }}
          </p>
          <ul class="menu-list">
            <li v-for="prefix in prefixes" :key="prefix.name">
              <router-link
                :to="{
                  name: 'documents',
                  params: { folder: prefix.path },
                  query: $route.query
                }"
              >
                {{ prefix.name }}
              </router-link>
            </li>
          </ul>
          <p class="menu-label">{{ $t('documents_documents') }}</p>
          <ul class="menu-list">
            <li v-for="i in items" :key="i.name">
              <a
                @click="view(i)"
                :class="{ 'is-active': item && i.name === item.name }"
              >
                <input type="checkbox" :value="i" v-model="itemsSelected" />
                {{ i.name }}
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <div class="flex-1">
        <div v-if="itemsSelectedCount > 0">
          <div class="columns">
            <div class="column is-12-mobile is-9-tablet is-6-desktop">
              <div class="buttons">
                <!-- <div>
                    {{$tc('documents_selected_documents', itemsSelectedCount, {count: itemsSelectedCount})}}
                  </div> -->
                <sqr-button
                  icon="envelope"
                  :label-raw="
                    $tc('documents_selected_email', itemsSelectedCount, {
                      count: itemsSelectedCount
                    })
                  "
                  @click="email()"
                  size="medium"
                  color="primary"
                  is-fullwidth
                  :is-loading="removing"
                  v-if="hasEmail"
                />
                <sqr-button
                  icon="trash"
                  :label-raw="
                    $tc('documents_selected_remove', itemsSelectedCount, {
                      count: itemsSelectedCount
                    })
                  "
                  @click="removeSelected()"
                  size="medium"
                  color="danger"
                  is-fullwidth
                  :is-loading="removing"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-doc" v-if="itemsSelectedCount === 0">
          <sqr-error :error="itemLoadError" />
          <div class="buttons" v-if="item">
            <a class="button" :href="itemURL" target="_blank">
              <span class="icon">
                <fa :icon="['fal', 'download']" />
              </span>
              <span>{{ $t('documents_download') }}</span>
            </a>
            <sqr-button
              icon="envelope"
              label="documents_email"
              @click="email()"
              :is-loading="renaming"
              v-if="hasEmail"
            />
            <sqr-button
              icon="edit"
              label="documents_rename"
              @click="rename()"
              :is-loading="renaming"
            />
            <sqr-button
              icon="trash"
              label="documents_remove"
              @click="remove()"
              :is-loading="removing"
            />
          </div>
          <iframe
            v-if="itemURL"
            :src="itemURL"
            width="100%"
            style="height: 90vh;"
          ></iframe>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.sticky-doc {
  position: sticky;
  top: 7.5rem;
}
.sticky-upload {
  position: sticky;
  top: 7.5rem;
  background-color: white;
}
</style>

<script>
import SqrFilesUploader from '@/sqrd/SqrFilesUploader';
import { mapMutations } from 'vuex';

export default {
  name: 'Documents',
  components: { SqrFilesUploader },
  props: {
    bid: String,
    folder: String,
    hasEmail: Boolean
  },
  computed: {
    itemsSelectedCount() {
      return this.itemsSelected.length;
    }
  },
  data() {
    return {
      path: '',

      parent: null,

      loading: false,
      loadError: null,
      prefixes: [],
      items: [],
      itemsSelected: [],

      item: null,
      itemLoading: false,
      itemLoadError: null,
      itemURL: null,

      removing: false,
      renaming: false
    };
  },
  mounted() {
    this.list();
    if (this.hasEmail) {
      this.attachmentsClean();
    }
  },
  watch: {
    $route() {
      this.list();
      this.item = null;
      this.itemURL = null;
    }
  },
  methods: {
    async list() {
      try {
        this.loading = true;
        this.itemsSelected = [];
        const storageRef = this.$fb()
          .storage()
          .ref();

        const path = ['/brokers', this.bid, 'files', this.folder].join(
          '/'
        );

        const parts = this.folder.split('/');
        if (parts.length > 1) {
          this.parent = parts.slice(0, -1).join('/');
        } else {
          this.parent = null;
        }

        this.path = path;
        var listRef = storageRef.child(path);
        console.log(listRef);
        const res = await listRef.listAll();
        console.log(res);
        this.prefixes = res.prefixes.map(p => {
          console.log(p);
          return { name: p.name, path: [this.folder, p.name].join('/') };
        });
        this.items = res.items.map(p => {
          // console.log(p);
          return { name: p.name, fullPath: p.fullPath };
        });
      } catch (error) {
        this.loadError = error;
      } finally {
        this.loading = false;
      }
    },
    async view(item) {
      try {
        this.itemLoading = true;
        this.item = item;
        const res = await this.$fs()
          .ref(item.fullPath)
          .getDownloadURL();
        this.itemURL = res;
      } catch (error) {
        this.itemLoadError = error;
      } finally {
        this.itemLoading = false;
      }
    },
    async rename() {
      try {
        this.renaming = true;
        const matches = this.item.name.match(/^(.*)\.([^.]+)$/);
        if (matches) {
          const name = matches[1];
          const ext = matches[2];
          const newName = prompt(this.$t('documents_rename_confirm'), name);
          if (!newName) return;
          await this.$f('storageRename')({
            oldPath: this.path + '/' + this.item.name,
            newPath: this.path + '/' + newName + '.' + ext
          });
          await this.list();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.renaming = false;
      }
    },
    async remove() {
      const item = this.item;
      if (confirm(this.$t('documents_remove_confirm', this.item))) {
        try {
          this.removing = true;
          const res = await this.$fs()
            .ref(item.fullPath)
            .delete();
          await this.list();
          this.item = null;
          this.itemURL = null;
        } catch (error) {
          alert('Error while removing')
        } finally {
          this.removing = false;
        }
      }
    },
    async removeSelected() {
      const count = this.itemsSelected.length;
      if (
        confirm(
          this.$tc(
            'documents_selected_remove_confirm',
            count,
            { count },
            this.item
          )
        )
      ) {
        try {
          this.removing = true;
          await Promise.all(
            this.itemsSelected.map(item =>
              this.$fs()
                .ref(item.fullPath)
                .delete()
            )
          );
          await this.list();
        } catch {
          console.log('error while removing', error);
        } finally {
          this.removing = false;
        }
      }
    },
    ...mapMutations('emailAttachments', {
      attachmentsAdd: 'add',
      attachmentsClean: 'clean'
    }),
    email() {
      if (this.itemsSelected.length > 0) {
        this.attachmentsAdd({ attachments: this.itemsSelected });
      } else {
        this.attachmentsAdd({ attachments: [this.item] });
      }
      this.$emit('email');
    }
  },
  metaInfo() {
    return {
      title: [this.$route.query.title, this.$t('documents_title')]
        .join(' ')
        .trim()
    };
  }
};
</script>
